import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Contatti extends Component {
  initMap = () => {
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    let mapOptions = {
      // How zoomed in you want the map to start at (always required)
      zoom: 15,

      // The latitude and longitude to center the map (always required)
      center: new window.google.maps.LatLng(45.0539689, 11.781687),

      // How you would like to style the map.
      // This is where you would paste any style found on Snazzy Maps.
      styles: [
        {
          featureType: "administrative.locality",
          elementType: "all",
          stylers: [
            { hue: "#2c2e33" },
            { saturation: 7 },
            { lightness: 19 },
            { visibility: "on" },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text",
          stylers: [{ visibility: "on" }, { saturation: "-3" }],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#fd901b" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            { hue: "#ffffff" },
            { saturation: -100 },
            { lightness: 100 },
            { visibility: "simplified" },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            { hue: "#ffffff" },
            { saturation: -100 },
            { lightness: 100 },
            { visibility: "off" },
          ],
        },
        {
          featureType: "poi.school",
          elementType: "geometry.fill",
          stylers: [
            { color: "#f39247" },
            { saturation: "0" },
            { visibility: "on" },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            { hue: "#ff6f00" },
            { saturation: "100" },
            { lightness: 31 },
            { visibility: "simplified" },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#f39247" }, { saturation: "0" }],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            { hue: "#008eff" },
            { saturation: -93 },
            { lightness: 31 },
            { visibility: "on" },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [{ color: "#fd901b" }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.stroke",
          stylers: [
            { visibility: "on" },
            { color: "#f3dbc8" },
            { saturation: "0" },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels",
          stylers: [
            { hue: "#bbc0c4" },
            { saturation: -93 },
            { lightness: -2 },
            { visibility: "simplified" },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            { hue: "#e9ebed" },
            { saturation: -90 },
            { lightness: -8 },
            { visibility: "simplified" },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            { hue: "#e9ebed" },
            { saturation: 10 },
            { lightness: 69 },
            { visibility: "on" },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            { hue: "#e9ebed" },
            { saturation: -78 },
            { lightness: 67 },
            { visibility: "simplified" },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [{ color: "#e9e9e9" }],
        },
      ],
    }

    // Get the HTML DOM element that will contain your map
    // We are using a div with id="map" seen below in the <body>
    let mapElement = document.getElementById("map")

    // Create the Google Map using our element and options defined above
    let map = new window.google.maps.Map(mapElement, mapOptions)

    // Let's also add a marker while we're at it
    new window.google.maps.Marker({
      position: new window.google.maps.LatLng(45.0539689, 11.781687),
      map: map,
      title: "Vision Care Italia",
    })
  }

  componentDidMount() {
    this.initMap()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Contatti"
          keywords={[`vision care`]}
          description="Contatti"
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={"page-title"}>Login</h1>

              <div id="map" />

              <h2 className={"b"}>Vision Care Italia Srl</h2>

              <dl className="contacts">
                <dt>Indirizzo</dt>
                <dd>Viale del Lavoro 10, 45100 Rovigo (RO)</dd>
                <dt>Telefono</dt>
                <dd>+39 049 704599</dd>
                <dt>Fax</dt>
                <dd>+39 049 8876292</dd>
                <dt>Email</dt>
                <dd>
                  <a href="mailto:servizioclienti@visioncareitalia.it">
                    servizioclienti@visioncareitalia.it
                  </a>
                </dd>
              </dl>

              <h3>Magazzino</h3>

              <dl className="contacts">
                <dt>Indirizzo</dt>
                <dd>Via Trentino 23, 35043 Monselice (PD)</dd>
              </dl>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contatti
